import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

// styles
const pageStyles = {
  color: "#232129",
  padding: "2em",
  fontFamily: "-apple-system, 'Roboto Condensed', sans-serif, serif",
}
const headingStyles = {
  color: '#1ba1e2',
  fontSize: '3em',
  lineHeight: '1.2em',
  marginTop: 0,
  marginBottom: 64,
  // maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
  fontSize: '2em'
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const NotFoundPage = () => {
  return (
    <Layout>
    <main style={pageStyles} className="container mx-auto">
      <title>Not found</title>
      <h1 style={headingStyles}>Oj!... Här var det visst tomt.</h1>
      <p style={paragraphStyles}>
        Sidan du försöker nå finns inte.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">Gå till startsidan..</Link>.
      </p>
    </main>
        </Layout>
  )
}

export default NotFoundPage
